/**
 * Environment variables utility for Vite
 *
 * This file provides a compatibility layer between Vite's import.meta.env approach
 * and the legacy Create React App's process.env pattern.
 */

// Create a process.env-like object for backward compatibility
interface ProcessEnv {
  [key: string]: string | undefined;

  // Vite environment variables (preferred)
  VITE_BACKEND_URL: string;
  VITE_NODE_ENV?: string;

  // Legacy Create React App environment variables (for backward compatibility)
  REACT_APP_BACKEND_URL: string;
  REACT_APP_NODE_ENV?: string;

  // Add other environment variables as needed
}

// Map Vite environment variables to process.env-like structure
export const env: ProcessEnv = {
  // Vite environment variables (preferred)
  VITE_BACKEND_URL: import.meta.env.VITE_BACKEND_URL || '',
  VITE_NODE_ENV: import.meta.env.VITE_NODE_ENV,

  // Legacy Create React App environment variables (for backward compatibility)
  REACT_APP_BACKEND_URL: import.meta.env.VITE_BACKEND_URL || '',
  REACT_APP_NODE_ENV: import.meta.env.VITE_NODE_ENV,

  // Add other environment variables as needed
};

// For backward compatibility with code that uses process.env directly
// This is a workaround and should be replaced with direct imports from this file
if (typeof window !== 'undefined') {
  // Using type assertion instead of @ts-ignore for better type safety
  (window as any).process = { env };
}

export default env;
