import React, { lazy, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { SnackbarProvider } from 'notistack';
import './styles/index.scss';
import { BrowserRouter } from 'react-router-dom';
import axios from 'axios';
import { HelmetProvider } from 'react-helmet-async';
import './utils/env';

// Lazy load the main App component
const App = lazy(() => import('./App'));

axios.defaults.withCredentials = true;

const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element');

const root = createRoot(container);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <HelmetProvider>
        <SnackbarProvider maxSnack={3}>
          <Suspense fallback={<div>Loading...</div>}>
            <App />
          </Suspense>
        </SnackbarProvider>
      </HelmetProvider>
    </BrowserRouter>
  </React.StrictMode>
);
